import { FaStar } from "react-icons/fa";

export const data = [
  {
        id: 7,
    icon: <FaStar />,
    class: "row row-1",
    animation:"animate__bounceIn",
    title: "Information Systems and Technologies Specialist",
    date: "Jul 2023 - Present",
    text: "As a Information Systems and Technologies Specialist, I am part of a innovative team working in an Agile/Scrum environment to develop robust and scalable software applications. In this role, I am actively involved in all aspects of the software development lifecycle. I proactively collaborate with cross-functional teams, driving the definition and design of innovative features. With a keen focus on aligning technical objectives with business goals, the objective is to ensure that the developed software adequately meets the needs of its users.",
    local: "STIC @ Aveiro University",
    link: "https://www.ua.pt/pt/stic",
    img: "/img/aveiro.png",
    techStack: ['React', 'TypeScript', 'ASP .NET Core 6', 'SQL Server', 'Kubernetes', 'Nginx', 'Azure DevOps'],
  },
  {
    id: 6,
    icon: <FaStar />,
    class: "row row-2",
    animation:"animate__bounceIn",
    title: "Platform Management Team Leader",
    date: "Jan 2023 - Oct 2023",
    text: "Responsible for developing and leading the team in charge of creating the web application that supports Portugal's national meeting of computer science students in Aveiro. The application allows attendees to sign up for challenges and workshops, and provides access to event information.",
    local: "Encontro Nacional de Estudantes de Informática",
    link: "https://www.enei23.pt/",
    img: "/img/enei.png",
    techStack: ['React', 'Tailwind', 'FastAPI', 'PostgreSQL', 'Docker', 'Nginx'],
  },
  {
    id: 1,
    icon: <FaStar />,
    class: "row row-1",
    animation:"animate__bounceIn",
    title: "Research & Development",
    date: "Jul 2022 - Jul 2023",
    text: "After successfully concluding my bachelor's degree final project with a classification of 19/20, I was invited, along with my colleagues, to continue to work on this project with the intent to make it closer to a production-ready product, set to be used by the university's community.",
    local: "STIC @ Aveiro University",
    link: "https://www.ua.pt/pt/stic",
    img: "/img/aveiro.png",
    techStack: ['React', 'TypeScript', 'ASP .NET Core 6', 'SQL Server', 'Kubernetes', 'Nginx', 'Azure DevOps'],
  },
  {
    id: 2,
    icon: <FaStar />,
    class: "row row-2",
    animation:"animate__bounceIn",
    title: "Internal Management Member",
    date: "Nov 2021 - Jul 2023",
    text: "Responsible for migrating the application technologies while also maintaining and creating new features for the course website.",
    local: "Núcleo de Estudantes de Informática AAUAV",
    link: "https://nei.web.ua.pt/",
    img: "/img/nei.png",
    techStack: ['React', 'PHP', 'FastAPI', 'MySQL', 'PostgreSQL', 'Docker', 'Nginx'],

  },
  {
    id: 3,
    icon: <FaStar />,
    class: "row row-1",
    animation:"animate__bounceIn",
    title: "Business Relations Member",
    date: "Mar 2022 - Oct 2022",
    text: "Contact companies, speakers and individual entities in order to organize events. Additionally, I played a pivotal role in IT, overseeing the development and maintenance of the website.",
    local: "Scientific Junior Value",
    link: "https://www.scientific.pt/",
    img: "/img/scientifi.png",
    techStack: ['React', 'GraphQL'],

  },
  {
    id: 4,
    icon: <FaStar />,
    class: "row row-2",
    animation:"animate__bounceIn",
    title: "External Collaborator",
    date: "Oct 2019 - Nov 2021",
    text: "Helping with tasks, such as organizing events, games or student meetings.",
    local: "Núcleo de Estudantes de Informática AAUAV",
    link: "https://nei.web.ua.pt/",
    img: "/img/nei.png",
    techStack: [],

  },
  {
    id: 5,
    icon: <FaStar />,
    class: "row row-1",
    animation:"animate__bounceIn",
    title: "External Collaborator",
    date: "Dec 2019 - Fev 2020",
    text: "",
    local: "Associação Académica da Universidade de Aveiro",
    link: "https://www.ua.pt/",
    img: "/img/aauav.jpg",
    techStack: [],

  },
];
