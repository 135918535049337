export const EducationData = [
  {
    id: 1,
    img: "/img/aveiro.png",
    text: "Computer Science Engineering MSc Degree",
    date: "Sep 2022 - Present",
  },
  {
    id: 2,
    img: "/img/aveiro.png",
    text: "Computer Science Engineering BSc Degree",
    date: "Sep 2019 - Jul 2022",
  },
  {
    id: 3,
    img: "/img/aese.jpg",
    text: "Scientific High School Degree",
    date: "Sep 2016 - Jul 2019",
  },
];
